const productRouter = {
  route: null,
  name: null,
  title: '兑换商品',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/merchant/', // 文件路径
  order: 3,
  inNav: true,
  permission: ['商品列表'],
  children: [
    {
      route: '/product/category',
      name: null,
      title: '商品类目',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      filePath: 'view/productCategory/',
      inNav: true,
      children: [
        {
          title: '商品类目列表',
          type: 'view',
          name: 'categoryList',
          route: '/product/category/list',
          filePath: 'view/product-category/list.vue',
          inNav: true,
          icon: 'iconfont icon-huiyuanguanli',
          isElementIcon: false,
        },
        {
          title: '添加商品类目',
          type: 'view',
          name: 'addCategory',
          route: '/product/category/add',
          filePath: 'view/product-category/create.vue',
          inNav: true,
          icon: 'iconfont icon-huiyuanguanli',
          isElementIcon: false,
        },
      ],
    },
    {
      title: '商品列表',
      type: 'view',
      name: 'product',
      route: '/product/list',
      filePath: 'view/product/list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli',
      permission: ['商品列表'],
    },
    {
      title: '添加商品',
      type: 'view',
      name: 'addProduct',
      route: '/product/add',
      filePath: 'view/product/create.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['添加商品'],
    },
  ],
}

export default productRouter
