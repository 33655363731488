const memberRouter = {
  route: null,
  name: null,
  title: '宣传片',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-shangpinguanli',
  filePath: 'view/trailer/', // 文件路径
  order: 5,
  inNav: true,
  permission: ['宣传片列表'],
  children: [
    {
      title: '宣传片列表',
      type: 'view',
      name: 'TrailerList',
      route: '/trailer/list',
      filePath: 'view/trailer/trailer-list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      permission: ['宣传片列表'],
    },
    {
      title: '添加宣传片',
      type: 'view',
      name: 'TrailerAdd',
      route: '/trailer/add',
      filePath: 'view/trailer/trailer-add.vue',
      inNav: true,
      icon: 'iconfont icon-tianjia',
      permission: ['添加宣传片'],
    },
    {
      title: '编辑宣传片',
      type: 'view',
      name: 'TrailerEdit',
      route: '/trailer/edit',
      filePath: 'view/trailer/trailer-edit.vue',
      inNav: false,
      icon: 'iconfont icon-fuwenbenbianjiqi_gongshi',
      permission: ['编辑宣传片'],
    },
  ],
}

export default memberRouter
