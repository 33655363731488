const memberRouter = {
  route: null,
  name: null,
  title: '攻略分享',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-fenxiang',
  filePath: 'view/member/', // 文件路径
  order: 4,
  inNav: true,
  permission: ['攻略分享列表'],
  children: [
    {
      title: '攻略分享列表',
      type: 'view',
      name: 'ShareList',
      route: '/share/list',
      filePath: 'view/share/share-list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      permission: ['攻略分享列表'],
    },
    {
      title: '添加攻略分享',
      type: 'view',
      name: 'ShareAdd',
      route: '/share/add',
      filePath: 'view/share/share-add.vue',
      inNav: true,
      icon: 'iconfont icon-tianjia',
      permission: ['添加攻略分享'],
    },
    {
      title: '编辑攻略分享',
      type: 'view',
      name: 'ShareEdit',
      route: '/share/edit',
      filePath: 'view/share/share-edit.vue',
      inNav: false,
      icon: 'iconfont icon-fuwenbenbianjiqi_gongshi',
      permission: ['编辑攻略分享'],
    },
  ],
}

export default memberRouter
