const merchantRouter = {
  route: null,
  name: null,
  title: '商家中心',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/merchant/', // 文件路径
  order: 3,
  inNav: true,
  permission: ['商家列表'],
  children: [
    {
      route: '/merchant/tags',
      name: null,
      title: '商家分类',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-huiyuanguanli',
      isElementIcon: false,
      filePath: 'view/merchantTags/',
      inNav: true,
      children: [
        {
          title: '商家分类列表',
          type: 'view',
          name: 'tagList',
          route: '/merchant/tags/list',
          filePath: 'view/merchantTags/tag-list.vue',
          inNav: true,
          icon: 'iconfont icon-huiyuanguanli',
          isElementIcon: false,
        },
        {
          title: '添加商家分类',
          type: 'view',
          name: 'tagList',
          route: '/merchant/tags/add',
          filePath: 'view/merchantTags/create.vue',
          inNav: true,
          icon: 'iconfont icon-huiyuanguanli',
          isElementIcon: false,
        },
      ],
    },
    {
      title: '商家列表',
      type: 'view',
      name: 'merchantList',
      route: '/merchant/list',
      filePath: 'view/merchant/merchant-list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli',
      permission: ['商家列表'],
    },
    {
      title: '添加商家',
      type: 'view',
      name: 'addMerchant',
      route: '/merchant/add',
      filePath: 'view/merchant/merchant-create.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['创建商家'],
    },
  ],
}

export default merchantRouter
