const memberRouter = {
  route: null,
  name: null,
  title: '会员管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/member/', // 文件路径
  order: 2,
  inNav: true,
  permission: ['查询所有会员'],
  children: [
    {
      title: '会员列表',
      type: 'view',
      name: 'MemberList',
      route: '/member/list',
      filePath: 'view/member/member-list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      permission: ['查询所有会员'],
    },
    {
      title: '积分列表',
      type: 'view',
      name: 'IntegralList',
      route: '/integral/list',
      filePath: 'view/integral/index.vue',
      inNav: true,
      icon: 'iconfont icon-daima1',
      permission: ['查询积分列表'],
    },
    {
      title: '打卡列表',
      type: 'view',
      name: 'ClockList',
      route: '/clock/list',
      filePath: 'view/clock/index.vue',
      inNav: true,
      icon: 'iconfont icon-xsaaa',
      permission: ['查询打卡列表'],
    },
    {
      title: '签到列表',
      type: 'view',
      name: 'SignList',
      route: '/sign/list',
      filePath: 'view/sign/index.vue',
      inNav: true,
      icon: 'iconfont icon-pinglun',
      permission: ['查询签到列表'],
    },
  ],
}

export default memberRouter
