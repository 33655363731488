const productRouter = {
  route: null,
  name: null,
  title: '兑换订单',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/order/', // 文件路径
  order: 3,
  inNav: true,
  permission: ['订单列表'],
  children: [
    {
      title: '兑换订单列表',
      type: 'view',
      name: 'product',
      route: '/order/list',
      filePath: 'view/order/list.vue',
      inNav: true,
      icon: 'iconfont icon-shangpinguanli'
    }
  ],
}

export default productRouter
